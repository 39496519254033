import React, { useState } from "react";
import cx from "classnames";
import { Slide } from "@material-ui/core/";
import ScrollLock from "react-scrolllock";
import { BlockCopy } from "src/components/global/block-copy";

import * as styles from "./info-drawer.module.css";

import { ReactComponent as CloseX } from "src/images/svg/x.svg";

interface InfoDrawerProps {
	title?: string;
	content?: [];
	open: boolean;
	toggle: () => void;
}

export const InfoDrawer = ({
	title,
	content,
	open,
	toggle,
}: InfoDrawerProps) => {
	return (
		<Slide
			direction="left"
			in={open}
			timeout={{
				enter: 400,
				exit: 200,
			}}
		>
			<div className={styles.drawer}>
				<button className={cx("corner-x", styles.closeButton)} onClick={toggle}>
					<CloseX />
				</button>
				<ScrollLock isActive={open}>
					<div className={styles.drawerContent}>
						<h2 className={cx("sans sans--20 upper medium", styles.title)}>
							{title}
						</h2>
						<div className={cx("sans sans--body-14 sans--light", styles.copy)}>
							<BlockCopy content={content} />
						</div>
					</div>
				</ScrollLock>
			</div>
		</Slide>
	);
};
