import React, { useState, useEffect } from "react";
import { Router } from "@reach/router";

import { Loading } from "src/components/global/loading";

import Homepage from "src/templates/homepage";
import Building from "src/templates/building";
import Product from "src/templates/product";

import { homepageQuery, buildingQuery, productQuery } from "src/api/preview";

const sanityClient = require("@sanity/client");

const client = sanityClient({
	projectId: process.env.GATSBY_SANITY_PROJECT_ID,
	dataset: process.env.GATSBY_SANITY_DATASET,
	useCdn: false, // `false` if you want to ensure fresh data
	token: process.env.GATSBY_SANITY_PREVIEW_TOKEN,
});

const PreviewPage = ({ document }) => {
	const [pageType, setPageType] = useState(null);
	const [pageData, setPageData] = useState(null);
	const [subscription, setSubscription] = useState(null);
	const [query, setQuery] = useState(null);

	const queryType = `*[_id == "${document}"] {
			...,
		}`;

	const queryPreviewHomepage = `*[_id == "${document}" ] {
			${homepageQuery}
    }`;

	const queryPreviewBuilding = `*[_id == "${document}" ] {
			${buildingQuery}
		}`;

	const queryPreviewProduct = `*[_id == "${document}" ] {
			${productQuery}
		}`;

	// First set query by document type
	useEffect(() => {
		client
			.fetch(queryType)
			.then((response) => {
				setPageType(response[0]._type);
				switch (response[0]._type) {
					case "homepage":
						setQuery(queryPreviewHomepage);
						break;
					case "building":
						setQuery(queryPreviewBuilding);
						break;
					case "product":
						setQuery(queryPreviewProduct);
						break;
					default:
						return;
				}
			})
			.catch((error) => {
				console.log("problem", error);
			});
	}, [
		document,
		queryPreviewHomepage,
		queryPreviewBuilding,
		queryPreviewProduct,
		queryType,
	]);

	// Then fetch document data
	useEffect(() => {
		if (query) {
			client
				.fetch(query)
				.then((response) => {
					setPageData(response[0]);
				})
				.catch((error) => {
					console.log("problem", error);
				});
		}
	}, [query]);

	// Finally, subscribe to query for live updates
	useEffect(() => {
		setSubscription(
			client.listen(query, {}, { includeResult: false }).subscribe((update) => {
				if (timeout) {
					clearTimeout(timeout);
				}
				let timeout = setTimeout(() => {
					client.fetch(query).then((response) => {
						setPageData(response[0]);
					});
				}, 1000);
			})
		);

		// // Unsubscribe from query
		// return function unsubscribe() {
		// 	if (subscription) {
		// 		subscription.unsubscribe();
		// 	}
		// };
	}, [query]);

	if (pageData) {
		console.log(pageData.content);
		switch (pageType) {
			case "homepage":
				return <Homepage pageContext={pageData.content} />;
			case "building":
				return <Building pageContext={pageData.content} />;
			case "product":
				return <Product pageContext={pageData.content} />;
			default:
				break;
		}
	} else {
		return <Loading />;
	}
};

const Preview = () => {
	return (
		<Router>
			<PreviewPage path="/preview/:document" />
		</Router>
	);
};

export default Preview;
