import React, { useEffect, useState } from "react";
import cx from "classnames";
import { encode, decode } from "shopify-gid";

import { client, useAddItemToCart } from "src/context/siteContext";

import styles from "./form.module.css";

export const ProductForm = ({ productId }: { productId: number }) => {
	const addItemToCart = useAddItemToCart();
	const [adding, setAdding] = useState(false as boolean);
	const [available, setAvailable] = useState(false);
	const [activeVariantId, setActiveVariantId] = useState("" as string);
	const [inventoryChecked, setInventoryChecked] = useState(false);

	const form = React.createRef<HTMLFormElement>();

	useEffect(() => {
		if (!inventoryChecked) {
			const shopifyId = encode("Product", productId, {
				accessToken: process.env.GATSBY_SHOPIFY_TOKEN,
			});

			client.product.fetch(shopifyId).then((product: any) => {
				const decodedVariants = [] as any;
				product.variants.forEach((variant: any) => {
					decodedVariants.push({
						...variant,
						cleanId: parseInt(decode(variant.id).id, 0),
					});
				});
				setActiveVariantId(decodedVariants[0].id as string);
				setAvailable(decodedVariants[0].available);

				setInventoryChecked(true);
			});
		}
	}, [inventoryChecked]);

	const handleSubmit = (e: React.FormEvent) => {
		e.preventDefault();
		e.stopPropagation();
		setAdding(true);
		if (available) {
			addItemToCart(activeVariantId, 1).then(() => {
				setAdding(false);
			});
		}
	};

	return (
		<div className={styles.productForm}>
			<form onSubmit={(e) => handleSubmit(e)} ref={form}>
				<button
					type="submit"
					className={styles.addToCartButton}
					disabled={inventoryChecked && !available}
				>
					{!inventoryChecked ? (
						<span className="sans sans--caps-11 medium">Checking Stock...</span>
					) : (
						<span>
							{available ? (
								<span className="serif serif--60 lower">Add to Cart</span>
							) : (
								<span className="serif serif--60 lower">Out of Stock</span>
							)}
						</span>
					)}
				</button>
			</form>
		</div>
	);
};
