export function blocksToPlainText(blocks: any[]) {
	if (!blocks) {
		return;
	}
	return blocks
		.map((block: { _type: string; children: any }) => {
			if (block._type !== "block" || !block.children) {
				return "";
			}
			return block.children.map((child: { text: any }) => child.text).join("");
		})
		.join("\n\n");
}
