import React, { useEffect } from "react";
import {
	Element as ScrollElement,
	animateScroll as scroll,
	scroller,
} from "react-scroll";
import { useSetPage } from "src/context/siteContext";
import { SEO } from "src/components/SEO";
import { RevealOnMount } from "src/components/reveal-on-mount";
import { BuildingBanner } from "src/components/building/banner";
import { ApartmentsCarousel } from "src/components/apartments-carousel";
import { AmenityCarousel } from "src/components/amenity-carousel";
import { AdditionalAmenities } from "src/components/additional-amenities";
import { OutlinedLabelGraphic } from "src/components/outlined-label-graphic";
import { LeasingMenu } from "src/components/global/leasing-menu";

import { ReactComponent as AmenitiesSVG } from "src/images/svg/amenities.svg";

export interface BuildingProps {
	pageContext: {
		main: {
			slug: {
				current: string;
			};
			title: string;
			entrataPropertyId: number;
			applicationUrl: string;
			unitCategories: [];
			promoBanner: {
				enableBanner?: boolean;
				headline?: string;
				finePrint?: string;
			};
			apartmentsCarousel: {
				title?: string;
				slides: [];
				verticalTitleSvg: {
					asset: {
						url: string;
					};
				};
			};
			amenitiesCarousels: {
				_key: string;
				title: string;
				graphic?:
				| {
					asset: {
						url: string;
					};
				}
				| undefined;
				slides: [];
			}[];
			additionalAmenities: [];
		};
		meta: {};
	};
	location: any;
	path: string;
}

const Building = ({ location, path, pageContext }: BuildingProps) => {
	const setPage = useSetPage();
	const { main, meta } = pageContext;

	useEffect(() => {
		setPage(main.slug.current);
	}, [0]);

	const {
		promoBanner,
		unitCategories,
		apartmentsCarousel,
		amenitiesCarousels,
		additionalAmenities,
	} = main;

	useEffect(() => {
		if (window && window.location.hash) {
			const amenityName = window.location.hash.replace("#", "");

			scroller.scrollTo(amenityName, {
				duration: 0,
				delay: 0,
				offset: -33, // offset header height less one pixel for border
				// duration: 900,
				// delay: 600,
				// smooth: true,
			});
		}
	}, []);

	return (
		<RevealOnMount location={location}>
			<SEO metaInfo={meta} pagePath={path} />

			<ApartmentsCarousel
				title={apartmentsCarousel.title}
				slides={apartmentsCarousel.slides}
				verticalTitleSvg={apartmentsCarousel.verticalTitleSvg}
				unitCategories={unitCategories}
				buildingSlug={main.slug.current}
			/>
			<div id="amenities">
				<OutlinedLabelGraphic SVGComponent={AmenitiesSVG} modifierClass={"amenities"} />
			</div>
			{amenitiesCarousels.map((carousel, index: number) => (
				<ScrollElement name={encodeURI(carousel.title)} key={carousel._key}>
					<AmenityCarousel
						graphic={carousel.graphic}
						slides={carousel.slides}
						index={index}
					/>
				</ScrollElement>
			))}
			<AdditionalAmenities amenities={additionalAmenities} />
			{promoBanner?.enableBanner && (
				<BuildingBanner promoBanner={promoBanner} />
			)}
			<LeasingMenu
				buildingSlug={main.slug.current}
				applicationUrl={main.applicationUrl}
			/>
		</RevealOnMount>
	);
};

export default Building;
