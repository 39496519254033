import React from "react";
import cx from "classnames";

import styles from "./banner.module.css";

interface BuildingBannerProps {
	promoMessaging: {
		headline: string;
		subheadline: string;
		finePrint: string;
	};
}

export const BuildingBanner = ({ promoBanner }: BuildingBannerProps) => {
	const { headline, finePrint } = promoBanner;

	return (
		<div className={styles.banner}>
			<div className={styles.inner}>
				<h4 className={cx("serif serif--31 lower", styles.headline)}>
					{headline}
				</h4>
				<p className={cx("sans sans--body-11 sans--light", styles.finePrint)}>
					{finePrint}
				</p>
			</div>
		</div>
	);
};
