import React, { useEffect } from "react";
import cx from "classnames";
import { useSetPage } from "src/context/siteContext";
import { SEO } from "src/components/SEO";
import { RevealOnMount } from "src/components/reveal-on-mount";
import { ProductHero } from "src/components/product/hero/hero";
import { ProductSchema } from "src/components/product/schema";
import { blocksToPlainText } from "src/utils/blocksToPlainText";

export interface ProductProps {
	location: any;
	pageContext: {
		main: {
			title: string;
			vendor?: {
				content: {
					vendorName: string;
					vendorCopy: [];
				};
			};
			slug: {
				current: string;
			};
			productDescription: any[];
			productDetails?: any[];
			shippingPolicies?: any[];
			productImages: [
				{
					_key: string;
					asset: {
						_id: string;
						url: string;
					};
				}
			];
		};
		meta: {
			metaTitle?: string;
			metaDescription?: string;
		};
		shopify: {
			productId: number;
			defaultPrice: string;
			defaultVariant: {
				sku: string;
				price: string;
			};
		};
	};
}

const Product = ({ location, pageContext }: ProductProps) => {
	const setPage = useSetPage();
	const { main } = pageContext;
	const {
		main: { slug },
		meta,
		shopify,
	} = pageContext;
	useEffect(() => {
		setPage(slug.current);
	}, [0]);

	const url = `products/${slug.current}`;

	// set metaInfo programmatically
	const metaInfo = {
		metaTitle: meta?.metaTitle || main.title,
		metaDescription:
			meta?.metaDescription || blocksToPlainText(main.productDescription),
		openGraphImage:
			main.productImages &&
			main.productImages.length &&
			main.productImages[0].asset.url,
	};

	return (
		<RevealOnMount location={location}>
			<SEO metaInfo={metaInfo} pagePath={url} />
			<div
				dangerouslySetInnerHTML={{
					__html: `
            <script type="application/ld+json">
              ${JSON.stringify(ProductSchema(main, shopify))}
            </script>
          `,
				}}
			/>
			<div className={cx(main.slug.current)}>
				<ProductHero main={main} product={shopify} />
			</div>
		</RevealOnMount>
	);
};

export default Product;
