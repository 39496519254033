import React, { useState } from "react";
import cx from "classnames";
import { BlockCopy } from "src/components/global/block-copy";
import { Image } from "src/components/image";
import { ProductForm } from "../form";
import { InfoDrawer } from "../info-drawer";

import { ReactComponent as DollarSign } from "src/images/svg/dollar-sign.svg";

import styles from "./hero.module.css";

export const ProductHero = ({
	product,
	main: {
		title,
		vendor,
		productDescription,
		productDetails,
		shippingPolicies,
		productImages,
	},
}: {
	main: {
		title?: string;
		vendor?: {
			content: {
				vendorName: string;
				vendorCopy: [];
			};
		};
		slug: {};
		productDescription?: [];
		productDetails?: [];
		shippingPolicies?: [];
		productImages?: [
			{
				_key: string;
				asset: {
					_id: string;
				};
			}
		];
	};
	product: {
		defaultPrice: string;
		productId: number;
	};
}) => {
	const [vendorDrawerOpen, setVendorDrawerOpen] = useState(false);
	const [detailsDrawerOpen, setDetailsDrawerOpen] = useState(false);
	const [policiesDrawerOpen, setPoliciesDrawerOpen] = useState(false);

	const toggleVendorDrawer = () => {
		setDetailsDrawerOpen(false);
		setPoliciesDrawerOpen(false);
		setVendorDrawerOpen(!vendorDrawerOpen);
	};

	const toggleDetailsDrawer = () => {
		setVendorDrawerOpen(false);
		setPoliciesDrawerOpen(false);
		setDetailsDrawerOpen(!detailsDrawerOpen);
	};

	const togglePoliciesDrawer = () => {
		setVendorDrawerOpen(false);
		setDetailsDrawerOpen(false);
		setPoliciesDrawerOpen(!policiesDrawerOpen);
	};

	return (
		<div className={styles.hero}>
			<div className={styles.inner}>
				<div className={styles.imageColumn}>
					{productImages &&
						productImages.map((image, i) => (
							<Image
								key={image._key}
								className={cx(
									"x y",
									styles.productImage,
									i === 0 ? styles.featured : ""
								)}
								imageId={image.asset._id}
								alt={title}
								style={{ height: "100%" }}
								objectFit={i === 0 ? "contain" : "cover"}
							/>
						))}
				</div>
				<div className={styles.copyColumn}>
					<div className={styles.copyContainer}>
						<h1 className={cx("sans sans--20 upper medium")}>{title}</h1>
						{vendor && (
							<h2 className={cx("sans sans--20 sans--light upper")}>
								{vendor.content.vendorName}
							</h2>
						)}
						<span className={cx("serif serif--50", styles.price)}>
							<DollarSign />
							{new Intl.NumberFormat().format(parseFloat(product.defaultPrice))}
						</span>
						{productDescription && (
							<div
								className={cx(
									"sans sans--body-14 sans--light",
									styles.description
								)}
							>
								<BlockCopy content={productDescription} />
							</div>
						)}
					</div>
					{vendor && (
						<InfoDrawer
							title={vendor.content.vendorName}
							content={vendor.content.vendorCopy}
							open={vendorDrawerOpen}
							toggle={toggleVendorDrawer}
						/>
					)}
					{productDetails && (
						<InfoDrawer
							title="Details"
							content={productDetails}
							open={detailsDrawerOpen}
							toggle={toggleDetailsDrawer}
						/>
					)}
					{shippingPolicies && (
						<InfoDrawer
							title="Shipping &amp; Policies"
							content={shippingPolicies}
							open={policiesDrawerOpen}
							toggle={togglePoliciesDrawer}
						/>
					)}
				</div>
				<div className={styles.sidebar}>
					<ProductForm {...product} />
					<div className={styles.detailLinks}>
						{productDetails && (
							<button
								className={cx(
									"sans sans--caps-11 medium",
									styles.infoDrawerButton
								)}
								onClick={() => toggleDetailsDrawer()}
							>
								Details
							</button>
						)}
						{vendor && (
							<button
								className={cx(
									"sans sans--caps-11 medium",
									styles.infoDrawerButton
								)}
								onClick={() => toggleVendorDrawer()}
							>
								About
								<br />
								{vendor.content.vendorName}
							</button>
						)}
						{shippingPolicies && (
							<button
								className={cx(
									"sans sans--caps-11 medium",
									styles.infoDrawerButton
								)}
								onClick={() => togglePoliciesDrawer()}
							>
								Shipping &amp; Policies
							</button>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};
