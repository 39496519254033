export const homepageQuery = `
  ...,
  content {
    ...,
    main {
      ...,
      intro {
        ...,
        building->,
        buildingIllustration {
          asset->
        },
        buildingLogo {
          asset->
        },
        developmentLogo {
          asset->
        }
      },
      modules[] {
        ...,
        _type == 'amenitiesCarousel' => {
          ...,
          building->,
          slides[] {
            ...,
            image {
              asset->
            }
          }
        },
        _type == 'buildingModule' => {
          ...,
          image {
            ...,
            asset->
          },
          building->
        },
        _type == 'shopModule' => {
          ...,
          collections[] {
            ...,
            collection->,
            image {
              ...,
              asset->
            }
          }
        },
        _type == 'pressCarousel' => {
          ...,
          slides[] {
            ...,
            logo {
              ...,
              asset->
            }
          }
        }
      }
    }
  }
`;

export const buildingQuery = `
  ...,
  content {
    main {
      ...,
      apartmentsCarousel {
        ...,
  			verticalTitleSvg {
          ...,
  				asset->
        },
        slides[] {
          ...,
          _type == 'carouselSlideMultiLayout' => {
            ...,
            svgPoster {
              ...,
              asset->
            },
            media {
              image {
                asset->
              },
              mp4 {
                asset->
              }
            }
          },
          _type == 'carouselSlidePaint' => {
            ...,
            svgPoster {
              ...,
              asset->
            },
            paintColors[] {
              ...,
              mainImage {
                ...,
                asset->
              },
              swatchImage {
                ...,
                asset->
              }
            },
          },
        }
      },
      amenitiesCarousels[] {
        ...,
        slides[] {
          ...,
          svgPoster {
              ...,
              asset->
            },
          media {
            image {
              asset->
            },
            mp4 {
              asset->
            }
          }
        }
      }
    }
  }
`;

export const productQuery = `
  ...,
  content {
    main {
      ...,
      productImages[] {
        ...,
        asset->
      },
      vendor->
    },
    shopify {
      ...
    }
  }
`;
